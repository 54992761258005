
import { bemJoin } from 'bem-join';
import React from 'react';

import Headline from '@eventstrive/components/Headline';
import Content from '@eventstrive/components/Content';
import Button from 'atoms/Button';
import CapabilityList from 'molecules/CapabilityList';

import './ProductIntro.scss';

const b = bemJoin('why');

const ProductIntro = () => (
  <Content>
    <Headline size={2} text="I’m an event organizer" wrap isCentered />
    <div id="why" className={b()}>
      <div>
        <CapabilityList capabilities={[
          { text: 'Long term revenue stream through preserved events' },
          { text: 'Tap into a worldwide audience' },
          { text: 'Hybrid events are trending - connect your local event with an online audience' },
        ]} />
      </div>
      <div className={b('cta')}>
        <Headline size={3} text="Interested in organizing an online event?" wrap />
        <Button
          to="mailto:info@eventstrive.com"
          text="Reach out to us"
          color="primary"
          isLarge
        />
      </div>
    </div>
  </Content>
);

export default ProductIntro;
