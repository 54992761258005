
import { bemJoin } from 'bem-join';
import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { Link } from 'gatsby';
import { FiChevronDown } from 'react-icons/fi';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import Content from '@eventstrive/components/Content';
import Headline from '@eventstrive/components/Headline';
import Button from 'atoms/Button';
import videoWall from 'videos/video_wall.mp4';

import './IndexHead.scss';

const b = bemJoin('index-head');

const IndexHead = () => {
  const [newsletterMessage, setNewsletterMesage] = useState<string>();

  const handleSubmit = async (e) => {
    const formElement = e.target;
    const formData = new FormData(e.target);
    e.preventDefault();
    const result: {
      result: string;
      msg: string;
    } = await addToMailchimp(formData.get('email'));
    const htmlPos = result.msg.indexOf('<');
    const msgEnd = htmlPos !== -1 ? htmlPos : result.msg.length;
    setNewsletterMesage(result.msg.substring(0, msgEnd));
    formElement.querySelector('input').value = '';
  };

  return (
    <div className={b()}>
      <Content>
        <div className={b('content')}>
          <Headline size={1} text="Events on Demand" wrap />
          <p>
          Get access to a whole new event experience and participate in live events or watch them whenever you like.
          </p>
          <form className={b('newsletter')} onSubmit={handleSubmit}>
            <input type="email" name="email" placeholder="Email address" />
            <Button text="Get early access" color="primary" depth={false} isLarge isSubmit />
          </form>
          {
            newsletterMessage
              ? <p className={b('newsletter-message')}>{newsletterMessage}</p>
              : (
                <div className={b('newsletter-hint')}>
                  By clicking on "Get early access", you acknowledge that your information will be
                  transferred to Mailchimp for processing.
                  <OutboundLink href="https://mailchimp.com/legal/" target="_blank" rel="noopener">
                    Learn more about Mailchimp's privacy practices here.
                  </OutboundLink>
                </div>
              )
          }
        </div>
      </Content>
      <video className={b('video')} src={videoWall} autoPlay muted loop playsInline />
      <Link className={b('scroll')} to="/#features">
        <FiChevronDown size={36} />
      </Link>
    </div>
  );
};

export default IndexHead;
